import {PropsWithChildren, ReactNode} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Box} from "@mui/material";
import Header from "../Header/Header";
import StickyFooter from "../Footer/Footer";


interface PageProps {
    description: string;
    elements?: ReactNode;
    keywords?: string;
    title: string;
}

export const Page = (
    {
        children,
        description,
        elements,
        keywords,
        title,
    }: PropsWithChildren<PageProps>): JSX.Element => (
    <HelmetProvider>
        <Helmet defaultTitle={'Bio-energy'} titleTemplate={`Bio-energy | %s`}>
            <meta name="description" content={description}/>
            {keywords && <meta name="keywords" content={keywords}/>}
            <title>{title}</title>
            {elements}
        </Helmet>
        <Box >
            <Header />
            <Box height='80%'>
                {children}
            </Box>
            <StickyFooter />
        </Box>
    </HelmetProvider>
);
