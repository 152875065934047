const Img5 = () => {

    return(
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="4.16667" fill="#4CAF50"/>
            <path d="M70.3333 50C70.3333 61.4451 68.0127 71.7821 64.2842 79.2392C60.5407 86.7262 55.4616 91.1667 50 91.1667C44.5385 91.1667 39.4593 86.7262 35.7158 79.2392C31.9873 71.7821 29.6667 61.4451 29.6667 50C29.6667 38.5549 31.9873 28.2179 35.7158 20.7608C39.4593 13.2738 44.5385 8.83333 50 8.83333C55.4616 8.83333 60.5407 13.2738 64.2842 20.7608C68.0127 28.2179 70.3333 38.5549 70.3333 50Z" stroke="#4CAF50"/>
            <path d="M60.1666 67.6086C50.2549 73.3311 40.1425 76.4899 31.8202 76.9894C23.4645 77.491 17.0794 75.3126 14.3486 70.5827C11.6178 65.8529 12.9238 59.234 17.536 52.2485C22.1298 45.2909 29.9215 38.1128 39.8333 32.3902C49.7451 26.6676 59.8574 23.5089 68.1798 23.0093C76.5355 22.5078 82.9206 24.6862 85.6514 29.416C88.3821 34.1459 87.0761 40.7648 82.4639 47.7503C77.8702 54.7078 70.0784 61.886 60.1666 67.6086Z" stroke="#4CAF50"/>
            <path d="M60.1666 32.3906C50.2549 26.668 40.1425 23.5093 31.8202 23.0098C23.4645 22.5082 17.0794 24.6866 14.3486 29.4165C11.6178 34.1463 12.9238 40.7652 17.536 47.7507C22.1298 54.7083 29.9215 61.8864 39.8333 67.609C49.7451 73.3316 59.8574 76.4903 68.1798 76.9898C76.5355 77.4914 82.9206 75.313 85.6514 70.5831C88.3821 65.8533 87.0761 59.2344 82.4639 52.2489C77.8702 45.2913 70.0784 38.1132 60.1666 32.3906Z" stroke="#4CAF50"/>
        </svg>

    )
}
export default Img5;