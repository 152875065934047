import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

function Copyright() {
    return (
        <Typography variant="body2" >
            Copyright © Bioenergy World Trade OÜ {new Date().getFullYear()}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

export default function Footer() {
    return (
        <Box
        py={5}
            className='footer' sx={{backgroundColor: '#D3D4D5'}}>
            <Container maxWidth="sm">

                <Copyright/>
            </Container>
        </Box>
    );
}