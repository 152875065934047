import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
//@ts-ignore
import img1 from '../../assets/using/img1.png';
//@ts-ignore
import img2 from '../../assets/using/img2.png';
//@ts-ignore
import img3 from '../../assets/using/img3.png';
//@ts-ignore
import img4 from '../../assets/using/img4.png';
import FadeInWhenVisible from "../../components/FadeInWhenVisible/FadeInWhenVisible";


const UsingSection = () => {
    const {t} = useTranslation();

    return (
        <Box py={5}>
            <FadeInWhenVisible threshold={1}>
                <Container>
                    <Typography variant='h3'>
                        {t('using.mainTitle')}
                    </Typography>
                    <Typography fontSize={'1.2rem'} color='secondary'>
                        {t('using.subTitle')}
                    </Typography>
                </Container>
            </FadeInWhenVisible>
            <FadeInWhenVisible>
                <Box pt={5}
                     display='flex'
                     flexDirection={{xs: 'column', md: 'row'}}
                     gap={5}>
                    <Box width={{xs: '100%', md: '40%'}}
                         display='flex'
                         flexDirection='column'
                         alignItems={{xs: 'center', md: 'flex-end'}}
                         justifyContent='center'
                         textAlign={{xs: 'center', md: 'right'}}>
                        <Box width={{xs: '100%', md: '60%'}}>
                            <Typography variant='h4'>
                                {t('using.slideTitle1')}
                            </Typography>
                            <Typography color='secondary'>
                                {t('using.slideText1')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box width={{xs: '100%', md: '60%'}}>
                        <img src={img1} style={{width: '100%'}} alt=""/>
                    </Box>
                </Box>
            </FadeInWhenVisible>
            <FadeInWhenVisible>
                <Box pt={5}
                     display='flex'
                     flexDirection={{xs: 'column', md: 'row-reverse'}}
                     gap={5}>
                    <Box width={{xs: '100%', md: '40%'}}
                         display='flex'
                         flexDirection='column'
                         alignItems={{xs: 'center', md: 'flex-start'}}
                         justifyContent='center'
                         textAlign={{xs: 'center', md: 'left'}}>
                        <Box width={{xs: '100%', md: '60%'}}>
                            <Typography variant='h4'>
                                {t('using.slideTitle2')}
                            </Typography>
                            <Typography color='secondary'>
                                {t('using.slideText2')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box width={{xs: '100%', md: '60%'}}>
                        <img src={img2} style={{width: '100%'}} alt=""/>
                    </Box>
                </Box>
            </FadeInWhenVisible>

            <FadeInWhenVisible>
                <Box
                    pt={5}
                    flexDirection={{xs: 'column', md: 'row'}}
                    display='flex'
                    gap={5}>
                    <Box width={{xs: '100%', md: '40%'}}
                         display='flex'
                         flexDirection='column'
                         alignItems={{xs: 'center', md: 'flex-end'}}
                         justifyContent='center'
                         textAlign={{xs: 'center', md: 'right'}}>
                        <Box width={{xs: '100%', md: '60%'}}>
                            <Typography variant='h4'>
                                {t('using.slideTitle3')}
                            </Typography>
                            <Typography color='secondary'>
                                {t('using.slideText3')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box width={{xs: '100%', md: '60%'}}>
                        <img src={img3} style={{width: '100%'}} alt=""/>
                    </Box>
                </Box>
            </FadeInWhenVisible>

            <FadeInWhenVisible>
                <Box
                    pt={5}
                    display='flex'
                    flexDirection={{xs: 'column', md: 'row-reverse'}}
                    gap={5}>
                    <Box width={{xs: '100%', md: '40%'}}
                         display='flex'
                         flexDirection='column'
                         alignItems={{xs: 'center', md: 'flex-start'}}
                         justifyContent='center'
                         textAlign={{xs: 'center', md: 'left'}}>

                        <Box width={{xs: '100%', md: '60%'}}>
                            <Typography variant='h4'>
                                {t('using.slideTitle4')}
                            </Typography>
                            <Typography color='secondary'>
                                {t('using.slideText4')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box width={{xs: '100%', md: '60%'}}>
                        <img src={img4} style={{width: '100%'}} alt=""/>
                    </Box>
                </Box>
            </FadeInWhenVisible>
        </Box>
    )
}
export default UsingSection;