const Img2 = () => {

    return (
        <svg fill="#4CAF50" width="100" height="100" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M960 15 693.227 257.027 333.44 243.053 284.693 599.96 0 820.547l192 304.64-76.267 352 342.934 109.973 167.893 318.613L960 1769.56l333.44 136.213 167.893-318.613 342.934-109.973-76.267-352 192-304.64-284.693-220.587-48.747-356.907-359.893 13.974L960 15Zm352.747 616.427 147.84 153.813-600 577.28-402.774-402.773L608.64 808.92l254.933 254.827 449.174-432.32Z"
                fill-rule="evenodd"/>
        </svg>
    )
}

export default Img2;