import Img1 from "../../assets/TransparencyQuality/img1";
import Img2 from "../../assets/TransparencyQuality/img2";
import Img3 from "../../assets/TransparencyQuality/img3";
import Img4 from "../../assets/TransparencyQuality/img4";

import * as React from "react";
import {Box, Card, CardContent} from "@mui/material";
import FadeInWhenVisible from "../../components/FadeInWhenVisible/FadeInWhenVisible";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const TransparencyQuality = () => {
    return (
        <Box display='flex' flexDirection='column' alignItems='center' gap={4} mb={5} >
            <FadeInWhenVisible>
                <Container>
                    <Typography variant='h3'>
                        Transparency and Quality
                    </Typography>
                </Container>
            </FadeInWhenVisible>

            <Box display='flex' gap={2} flexWrap='wrap' justifyContent='center'>
                <FadeInWhenVisible threshold={1}>
                    <Card sx={{width: '330px'}}>
                        <CardContent>
                            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={2}>
                                <Img1/>
                                <Typography textAlign='center' variant='h5' color="#4CAF50" gutterBottom>
                                    Trust
                                </Typography>
                                <Typography>
                                    We are trusted by large international companies that adhere to the principles of
                                    increasing energy efficiency and overcome the challenges of climate change.
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </FadeInWhenVisible>
                <FadeInWhenVisible threshold={1}>
                    <Card sx={{width: '330px'}}>
                        <CardContent>
                            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={2}>
                                <Img2/>
                                <Typography textAlign='center' variant='h5' color="#4CAF50" gutterBottom>
                                    Certified according to the EU standard
                                </Typography>
                                <Typography>
                                    We are certified by the ISCC EU standard as a trader. At the end of 2023 we made
                                    recertification and received an updated trader certificate until 2025. We support
                                    the transparency and honesty of trade, which is why we have access to issue proof of
                                    sustainability in the Nabisy system.
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </FadeInWhenVisible>
                <FadeInWhenVisible threshold={1}>
                    <Card sx={{width: '330px'}}>
                        <CardContent>
                            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={2}>
                                <Img3/>
                                <Typography textAlign='center' variant='h5' color="#4CAF50" gutterBottom>
                                    Premium quality bioethanol
                                </Typography>
                                <Typography>
                                    We offer high-quality bioethanol, which is confirmed by such certificates as of the
                                    independent SGS laboratory.
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </FadeInWhenVisible>
                <FadeInWhenVisible threshold={1}>
                    <Card sx={{width: '330px'}}>
                        <CardContent>
                            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={2}>
                                <Img4/>
                                <Typography textAlign='center' variant='h5' color="#4CAF50" gutterBottom>
                                    Global partner
                                </Typography>
                                <Typography>
                                    Our logistics partner is SC "KN Energies" in Lithuania, the city of Klaipeda, where
                                    we store bioethanol. This means that you can get the product you want by loading it
                                    onto a vessel that will deliver it to any part of the world.
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </FadeInWhenVisible>
            </Box>
        </Box>
    );
}
export default TransparencyQuality;