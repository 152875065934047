import {Box} from "@mui/material";
import {Outlet} from "react-router-dom";
import {useEffect} from "react";

const Root = () => {

    useEffect(() => {
        // Перевіряємо, чи є мова в локальному сховищі
        const savedLanguage = localStorage.getItem('i18nextLng');

        // Якщо мова ще не встановлена, встановлюємо "en-US" за замовчуванням
        if (!savedLanguage || savedLanguage === 'en'){
            localStorage.setItem('i18nextLng', 'en-US');
        }
    }, []);

    return (
        <Box>
            <Outlet />
        </Box>
    )
}

export default Root;