const Img1 = () => {

    return(
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M50 16.6667L39.3522 34.413C38.4498 35.917 37.9986 36.669 37.9986 37.5C37.9986 38.3309 38.4498 39.083 39.3522 40.587L49.5713 57.6188C49.7627 57.9379 49.8585 58.0974 50 58.0974C50.1415 58.0974 50.2373 57.9379 50.4287 57.6188L60.6478 40.587C61.5502 39.083 62.0014 38.3309 62.0014 37.5C62.0014 36.669 61.5502 35.917 60.6478 34.413L50 16.6667ZM50 16.6667V8.33333" stroke="#4CAF50"/>
            <path d="M84.0011 52.492L63.3083 52.1439C61.5546 52.1144 60.6778 52.0996 59.9581 52.5151C59.2385 52.9306 58.8129 53.6973 57.9616 55.2309L48.3211 72.5967C48.1405 72.9221 48.0502 73.0848 48.121 73.2074C48.1917 73.3299 48.3778 73.3331 48.7499 73.3393L68.6094 73.6734C70.3631 73.7029 71.2399 73.7177 71.9596 73.3022C72.6792 72.8867 73.1048 72.12 73.9562 70.5864L84.0011 52.492ZM84.0011 52.492L91.2179 48.3253" stroke="#4CAF50"/>
            <path d="M15.9989 52.492L36.6917 52.1439C38.4454 52.1144 39.3222 52.0996 40.0419 52.5151C40.7615 52.9306 41.1871 53.6973 42.0384 55.2309L51.6789 72.5967C51.8595 72.9221 51.9498 73.0848 51.879 73.2074C51.8083 73.3299 51.6222 73.3331 51.2501 73.3393L31.3906 73.6734C29.6369 73.7029 28.7601 73.7177 28.0404 73.3022C27.3208 72.8867 26.8951 72.12 26.0438 70.5864L15.9989 52.492ZM15.9989 52.492L8.78207 48.3253" stroke="#4CAF50"/>
            <path d="M50 62.5V93.75" stroke="#4CAF50"/>
        </svg>
    )
}

export default  Img1;