import React, {ReactNode} from 'react';
import {useInView} from 'react-intersection-observer';
import {Fade} from '@mui/material';
import Box from "@mui/material/Box";

interface FadeInWhenVisibleProps {
    children: ReactNode;
    threshold?: number; // Необов'язковий параметр для визначення порогу входу у поле зору
    timeout?: number | { enter?: number, exit?: number }; // Додаємо prop для тривалості анімації
}

const FadeInWhenVisible: React.FC<FadeInWhenVisibleProps> = (
    {
        children,
        threshold = 0.5,
        timeout = 1400,
    }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: threshold,
    });

    return (
        <Fade in={inView} timeout={timeout} ref={ref}>
            <Box  zIndex={30}>{children}</Box>
        </Fade>
    );
};

export default FadeInWhenVisible;
