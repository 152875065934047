import Box from "@mui/material/Box";

//@ts-ignore
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import FadeInWhenVisible from "../../components/FadeInWhenVisible/FadeInWhenVisible";
import {useTranslation} from "react-i18next";

const AboutUsSection = () => {
    const {t} = useTranslation();

    return (
        <Box
            position='relative'
            height='80vh'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='flex-end'
        >
            <Box className='aboutUsBackground'/>
            <Box className="overlay"></Box>
            <FadeInWhenVisible>
                <Box zIndex={10} width={{sx: '100%', md: '80%'}} display='flex' justifyContent='flex-end' mx='auto'
                     position='relative'>
                    <Box className='textBackgroundBlock-right'/>
                    <Stack gap={2} px={5} py={2} width={{xs: 'auto', md: '60%'}} color='white'
                           sx={{backgroundColor: 'rgb(0,0,0,0.5)'}}>
                        <Typography variant='h4'>{t('aboutUs.mainTitle')}</Typography>
                        <Typography fontSize={{xs: '14px', md: '20px'}}>{t('aboutUs.subTitle')}</Typography>
                    </Stack>
                </Box>
            </FadeInWhenVisible>
        </Box>
    )
}

export default AboutUsSection