import {Page} from "../../components/Page/Page";
import ContactUsForm from "./ContactUsForm";
import {Card, CardContent, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import GoogleMapReact from 'google-map-react';

const ContactUs = () => {
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };

    return (
        <Page description={'Contact us'} title={'Contact Us'}>
            <Box paddingY='15vh' >
                <Container>
                    <Box
                        display='flex'
                        flexDirection={{xs: 'column', md: 'row'}}
                        gap={2}>
                        <Stack gap={2}>
                            <Card >
                                <CardContent>
                                    <Box display='flex' justifyContent='flex-start'>
                                        <Box>
                                            <Typography variant='h6'>
                                                <span style={{color: '#a3a1a1'}}>Address:</span> Harju maakond, Lasnamäe
                                                linnaosa, K. Kärberi tn 4-197, 13812, Tallinn,
                                                Estonia
                                            </Typography>
                                            <Typography variant='h6'>
                                                <span style={{color: '#a3a1a1'}}>Registration code:</span> 16539400
                                            </Typography>
                                            <Typography variant='h6'>
                                                <span style={{color: '#a3a1a1'}}>VAT number: </span> EE102524772
                                            </Typography>
                                            <Typography variant='h6'>
                                                <span style={{color: '#a3a1a1'}}>EORI number:</span> EE16539400
                                            </Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                            {/*<Card >*/}
                            {/*    <CardContent>*/}
                            {/*        <Box display='flex' justifyContent='flex-start'>*/}
                            {/*            <Box>*/}
                            {/*                <Typography variant='h6'>*/}
                            {/*                    <span style={{color: '#a3a1a1'}}>Beneficiary`s Acc No.:</span> PL 29*/}
                            {/*                    1020 1042 0000 8402 0567 5238*/}
                            {/*                </Typography>*/}
                            {/*                <Typography variant='h6'>*/}
                            {/*                    <span style={{color: '#a3a1a1'}}>Beneficiary`s bank:</span> PKO Bank*/}
                            {/*                    Polski SA*/}
                            {/*                </Typography>*/}
                            {/*                <Typography variant='h6'>*/}
                            {/*                    <span style={{color: '#a3a1a1'}}>Address: </span> Chlodna 52, 00-872*/}
                            {/*                    Warszawa*/}
                            {/*                </Typography>*/}
                            {/*                <Typography variant='h6'>*/}
                            {/*                    <span style={{color: '#a3a1a1'}}>SWIFT code: </span> BPKOPLPW*/}
                            {/*                </Typography>*/}
                            {/*            </Box>*/}
                            {/*        </Box>*/}
                            {/*    </CardContent>*/}
                            {/*</Card>*/}
                        </Stack>
                        <Box style={{height: 'auto', width: '40%'}}>
                            <GoogleMapReact
                                bootstrapURLKeys={{key: ""}}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                            >
                            </GoogleMapReact>
                        </Box>
                    </Box>
                </Container>
                <ContactUsForm/>
            </Box>

        </Page>
    )
}
export default ContactUs