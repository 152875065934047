import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import Root from "./pages/Root";
import Home from "./pages/Home/Home";
import {createTheme, ThemeProvider} from "@mui/material";
import './i18n';
import ContactUs from "./pages/ContactUs/ContactUs";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        children: [{
            index: true,
            element: <Home/>
        }]
    }, {
        path: '/contact-us',
        element: <ContactUs/>,
    }
]);

const theme = createTheme({
    palette: {
        primary: {
            main: '#212121',
        },
        secondary: {
            main: '#757575',
        },
    },
    components: {
        // Name of the component
        MuiContainer: {
            styleOverrides: {
                root: {
                    maxwidth: '1400px',
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    borderColor: '#fff',
                },
                outlined: {
                    border: 'none !important',
                },
            }
        }, MuiInput: {
            styleOverrides: {
                root: {
                    border: "none"
                },
            }
        }, MuiCard: {
            styleOverrides: {
                root: {
                    height: '100%',
                    background: '#e6fbff'
                }
            }
        }
    },
});

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <RouterProvider router={router}/>
        </ThemeProvider>
    </React.StrictMode>
);
