import Box from "@mui/material/Box";
import {Button, Stack, TextField} from "@mui/material";
import Container from "@mui/material/Container";
import FadeInWhenVisible from "../../components/FadeInWhenVisible/FadeInWhenVisible";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";

const ContactUsForm = () => {
    const {t} = useTranslation();
    return (
        <Box py={5}>
            <Container>
                <FadeInWhenVisible threshold={1}>
                    <Typography variant='h3' textAlign='center'>
                        {t('contactUs.mainTitle')}
                    </Typography>
                </FadeInWhenVisible>
                <FadeInWhenVisible threshold={0.4}>
                    <Stack py={5} gap={2}>
                        <TextField label={t('contactUs.labelName')}/>
                        <TextField label={t('contactUs.labelEmail')}/>
                        <TextField label={t('contactUs.labelPhone')}/>
                        <TextField multiline rows={5} label={t('contactUs.labelMessage')}/>
                        <Button variant='contained'>{t('contactUs.buttonSubmit')}</Button>
                    </Stack>
                </FadeInWhenVisible>
            </Container>
        </Box>

    )

}
export default ContactUsForm;