const Img1 = () => {

    return (
        <svg fill="#4CAF50" version="1.1" id="Layer_1"
             width="100" height="100" viewBox="0 0 260 184" >
<path d="M182.1,134.5c3.7-12.9,8-22.6,18.4-26.9c10.3-4.3,23.2-24.9,29.7-22c6.4,2.9,5.1,10,1.1,15.7c-4,5.7-11.2,13.8-11.2,13.8
	l31,8.4c0,0,7.3,2.4,6.9,8.2c-0.4,5.8-17.4,4.8-17.4,4.8s15.8,6.3,14.3,12.4s-19.2,2-19.2,2s13.7,8.9,10,15.1
	c-3.6,6.3-19.2-2.1-19.2-2.1s12.8,9.2,8.9,14.6c-3.9,5.4-18.8-4.8-18.8-4.8S172.9,166.8,182.1,134.5z M92.2,1.8
	C42.4,1.8,2,42.2,2,92c0,49.8,40.4,90.2,90.2,90.2c49.8,0,90.2-40.4,90.2-90.2C182.5,42.2,142.1,1.8,92.2,1.8z M55.7,61.3
	c8.6,0,15.5,6.9,15.5,15.5s-6.9,15.5-15.5,15.5s-15.5-6.9-15.5-15.5S47.2,61.3,55.7,61.3z M92.2,153.8c-21.3,0-40.6-12.6-49.1-32.2
	l12.9-5.6c6.2,14.4,20.4,23.7,36.1,23.7c15.7,0,29.9-9.3,36.1-23.7l12.9,5.6C132.8,141.2,113.6,153.8,92.2,153.8z M128.7,92.4
	c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S137.3,92.4,128.7,92.4z"/>
</svg>
    )
}

export default Img1;