import Box from "@mui/material/Box";
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Img1 from '../../assets/advantages/img1.js'
import Img2 from '../../assets/advantages/img2.js'
import Img3 from '../../assets/advantages/img3.js'
import Img4 from '../../assets/advantages/img4.js'
import Img5 from '../../assets/advantages/img5.js'
import FadeInWhenVisible from "../../components/FadeInWhenVisible/FadeInWhenVisible";
import Container from "@mui/material/Container";
import {useTranslation} from "react-i18next";
import {Card, CardContent} from "@mui/material";


const AdvantagesSection = () => {
    const {t} = useTranslation();

    return (
        <Box width='100%' height='auto' py={{xs: 4, md: 10}}>
            <FadeInWhenVisible threshold={1}>
                <Container>
                    <Typography variant='h3' textAlign='center'>
                        {t('advantages.mainTitle')}
                    </Typography>
                </Container>
            </FadeInWhenVisible>
            <Grid py={3} container spacing={2} display='flex' justifyContent='center'>
                <Grid item md={3} xs={11}>
                    <Card>
                        <CardContent>
                            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' textAlign='center'>
                                    <Img1/>
                                    <Typography variant='h5' color="#4CAF50" gutterBottom>
                                        {t('advantages.itemTitle1')}
                                    </Typography>
                                    <Typography>
                                        {t('advantages.itemText1')}
                                    </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid height='auto' item xs={11} md={3}>
                    <Card >
                        <CardContent>
                            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' textAlign='center'>
                                <Box mr={1}>
                                    <Img3/>
                                </Box>
                                <Box>
                                    <Typography variant='h5' color="#4CAF50" gutterBottom>
                                        {t('advantages.itemTitle3')}
                                    </Typography>
                                    <Typography>
                                        {t('advantages.itemText3')}
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={11} md={3}>
                    <Card >
                        <CardContent>
                            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' textAlign='center'>
                                <Box mr={1}>
                                    <Img4/>
                                </Box>
                                <Box>
                                    <Typography variant='h5' color="#4CAF50" gutterBottom>
                                        {t('advantages.itemTitle4')}
                                    </Typography>
                                    <Typography>{t('advantages.itemText4')}</Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={5} xs={11}>
                    <Card>
                        <CardContent>
                            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' textAlign='center'>
                                <Box mr={1}>
                                    <Img2/>
                                </Box>
                                <Box>
                                    <Typography variant='h5' color="#4CAF50" gutterBottom>
                                        {t('advantages.itemTitle2')}
                                    </Typography>
                                    <Typography>
                                        {t('advantages.itemText2')}
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={5} xs={11}>
                    <Card >
                        <CardContent>
                            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' textAlign='center'>
                                <Box mr={1}>
                                    <Img5/>
                                </Box>
                                <Box>
                                    <Typography variant='h5' color="#4CAF50" gutterBottom>
                                        {t('advantages.itemTitle5')}
                                    </Typography>
                                    <Typography>
                                        {t('advantages.itemText5')}
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </Box>
    )
}

export default AdvantagesSection