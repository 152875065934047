import Box from "@mui/material/Box";
import {Card, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";

import FadeInWhenVisible from "../../components/FadeInWhenVisible/FadeInWhenVisible";
import {useTranslation} from "react-i18next";

const TeamSection = () => {
    const {t} = useTranslation();

    return (
        <Box position='relative' width='100%' height='auto' py={{xs: 1, md: 5}} color='#fff' display='flex' justifyContent='center' flexDirection={{xs:'column', md: 'row'}} gap={2}>
            <Box className='ourTeamBackground '/>
            <Box className='blur'/>
            <Box py={3} display='flex' flexWrap='wrap' zIndex={30} alignItems='center' justifyContent='center' gap={2} >
                    <Card sx={{width: '330px', height: '100%', backgroundColor: 'rgb(138, 181, 251, 0.5)'}}>
                        <Box height='100%' display='flex' alignItems='center' justifyContent='center'>
                            <Typography zIndex={1000} variant='h3' textAlign='center' sx={{backgroundColor: '#4CAF50'}} color='#fff'>
                                {t('team.mainTitle')}
                            </Typography>
                        </Box>
                    </Card>
            </Box>

            <Box  display='flex' flexDirection='column' flexWrap='wrap' alignItems='center'
                 justifyContent='center' gap={2}>
                <FadeInWhenVisible threshold={1}>
                    <Card sx={{width: '330px', backgroundColor: 'rgb(138, 181, 251, 0.5)'}}>
                        <CardContent>
                            <Box textAlign='center' >
                                <Typography sx={{backgroundColor: '#4CAF50'}} color='#fff' fontWeight='bold' variant='h5' mb={2} >
                                    {t('team.teammate1Name')}
                                </Typography>
                                <Typography sx={{backgroundColor: '#4CAF50'}} color='#fff' >
                                    {t('team.teammate1Position')}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </FadeInWhenVisible>
                <FadeInWhenVisible threshold={1}>
                    <Card sx={{width: '330px', backgroundColor: 'rgb(138, 181, 251, 0.5)'}}>
                        <CardContent>
                            <Box textAlign='center' >
                                <Typography sx={{backgroundColor: '#4CAF50'}} color='#fff' fontWeight='bold' variant='h5' mb={2}>
                                    {t('team.teammate2Name')}
                                </Typography>
                                <Typography sx={{backgroundColor: '#4CAF50'}} color='#fff'>
                                    {t('team.teammate2Position')}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </FadeInWhenVisible>
                <FadeInWhenVisible threshold={1}>
                    <Card sx={{width: '330px', backgroundColor: 'rgb(138, 181, 251, 0.5)'}}>
                        <CardContent>
                            <Box textAlign='center' >
                                <Typography sx={{backgroundColor: '#4CAF50'}} color='#fff' fontWeight='bold' variant='h5' mb={2}>{t('team.teammate4Name')}</Typography>
                                <Typography sx={{backgroundColor: '#4CAF50'}} color='#fff'>
                                    {t('team.teammate4Position')}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </FadeInWhenVisible>
            </Box>
        </Box>
    )
}

export default TeamSection