import ProductionProcessSection from "./ProductionProcessSection";
import {Page} from "../../components/Page/Page";
import UsingSection from "./UsingSection";
import OurCompanySection from "./OurCompanySection";
import OurMissionSection from "./OurMissionSection";
import AboutUsSection from "./AboutUsSection";
import AdvantagesSection from "./AdvantagesSection";
import TeamSection from "./Team";
import TransparencyQuality from "./TransparencyQuality";

const Home = () => {

    return (
        <Page description={'Home'} title={'home'}>
            <OurCompanySection />
            <AboutUsSection />
            <OurMissionSection />
            <AdvantagesSection />
            <TeamSection />
            <UsingSection />
            <TransparencyQuality />
            {/*<ProductionProcessSection />*/}
        </Page>
    );
}
export default  Home;