import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
//@ts-ignore
import Logo from '../../assets/logos.png';
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import {Link, useLocation} from "react-router-dom";
import {Button} from "@mui/material";


function Header() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const location = useLocation();
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    window.addEventListener('scroll', function () {
        const header = document.querySelector('.header');
        const scrollPosition = window.scrollY;


        if (scrollPosition > 0) {
            header?.classList.add('scrolled');
        } else {
            header?.classList.remove('scrolled');
        }
    });


    return (
        <AppBar className={`header ${location.pathname === '/contact-us' ? 'header-fill' : ''}`}
                position="fixed"
                sx={{paddingY: '16px'}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{display: 'flex', justifyContent: 'space-between', width: '100%',}}>
                    <Link to='/'>
                        <Box display='flex'>
                            <img style={{width: '200px'}} src={Logo} alt=""/>
                        </Box>
                    </Link>

                    <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},

                            }}
                        >
                            <MenuItem  onClick={handleCloseNavMenu}>
                                <Link to='/' className='link'>
                                    <Button
                                        sx={{color: 'black', display: 'block'}}>
                                        Home
                                    </Button>
                                </Link>
                            </MenuItem>
                            <MenuItem color='black' onClick={handleCloseNavMenu}>
                                <Link to='/contact-us' className='link'>
                                    <Button
                                        sx={{color: 'black', display: 'block'}}>
                                        Contact us
                                    </Button>
                                </Link>
                            </MenuItem>
                        </Menu>

                    </Box>
                    <Box gap={2} justifyContent='center' alignItems='center' sx={{display: {xs: 'none', md: 'flex'}}}>
                        <Link to='/' className='link'>
                            <Button
                                sx={{color: 'white', display: 'block'}}>
                                Home
                            </Button>
                        </Link>
                        <Link to='/contact-us'>
                            <Button
                                sx={{color: 'white', display: 'block'}}>
                                Contact us
                            </Button>
                        </Link>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;