const Img4 = () => {

    return(
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1_26)">
                <path d="M87.5 66.6667C87.5 56.7211 83.5491 47.1828 76.5165 40.1502C69.4839 33.1176 59.9456 29.1667 50 29.1667C40.0544 29.1667 30.5161 33.1176 23.4835 40.1502C16.4509 47.1828 12.5 56.721 12.5 66.6667L33.3544 66.6667C33.3544 62.252 35.1081 58.0181 38.2298 54.8965C41.3514 51.7748 45.5853 50.0211 50 50.0211C54.4147 50.0211 58.6486 51.7748 61.7702 54.8965C64.8919 58.0181 66.6456 62.252 66.6456 66.6667H87.5Z" stroke="#4CAF50"/>
                <path d="M50 29.1667L50 50" stroke="#4CAF50"/>
                <path d="M76.5165 40.1489L62.4994 54.166" stroke="#4CAF50"/>
                <path d="M23.4834 40.1489L37.5006 54.1661" stroke="#4CAF50"/>
            </g>
            <defs>
                <filter id="filter0_d_1_26" x="-4" y="0" width="108" height="108" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_26"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_26" result="shape"/>
                </filter>
            </defs>
        </svg>

    )
}
export default Img4;