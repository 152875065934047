import Box from "@mui/material/Box";

//@ts-ignore
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import FadeInWhenVisible from "../../components/FadeInWhenVisible/FadeInWhenVisible";
import {useTranslation} from "react-i18next";

const OurCompanySection = () => {
    const {t} = useTranslation();

    return (
        <Box position='relative'
             height={{xs: '100vh', md: '90vh'}}
             display='flex'
             flexDirection='column'
             justifyContent='center'
             alignItems='center'>
            <Box className='ourCompanyBackground'/>
            <Box className='ourCompanyBackgroundMobile'/>
            <Box className="overlay"/>
            <FadeInWhenVisible>
                <Box zIndex={10} width={{sx: '100%', md: '80%'}} mx='auto' position='relative'>
                    <Box className='textBackgroundBlock'/>
                    <Stack gap={2} px={5} py={2} width={{xs: 'auto', md: '60%'}} color='white'
                           sx={{backgroundColor: 'rgb(0,0,0,0.5)'}}>
                        <Typography variant='h4'>{t('ourCompany.mainTitle')}</Typography>
                        <Typography fontSize={{xs: '14px', md: '20px'}} color='#d4d4d4' variant='h6'>{t('ourCompany.subtitle')}</Typography>
                        <Typography fontSize={{xs: '14px', md: '20px'}}> {t('ourCompany.text1')} <span
                            style={{color: '#8AB5FB'}}>{t('ourCompany.text2')} </span> {t('ourCompany.text3')}
                        </Typography>
                    </Stack>
                </Box>
            </FadeInWhenVisible>
            <Box display='flex'>
                <Box>

                </Box>
            </Box>

        </Box>
    )
}

export default OurCompanySection